.cinematic-v1_5 {
  @import "../shared/_defaults";
  @include display-header;
  @include origin-flag-simple;

  .display-header {
    background: linear-gradient(to bottom, rgba($black, .75), rgba($black, .5), rgba($black, 0));
  }

  .posts {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .post {
    font-size: 5vmin;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .5);
    color: $white;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .media-module {
    position: relative;
    width: 100%;
    height: 100%;
    float: left;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  .blurred-media-module {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .media {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .text-module {
    position: absolute;
    width: 100%;
    height: auto;
    max-height: calc(100% - 25vmin);
    padding: 5vmin 5%;
    overflow: hidden;
  }

  .text .text-module, .chat .text-module {
    @include vertical-align;
  }

  .has-media .text-module {
    bottom: 0;
    left: 0;
    font-size: .8em;
    background: linear-gradient(to top, rgba($black, .75), rgba($black, .5), rgba($black, 0));
  }

  .meta-author {
    position: relative;
    font-size: .8em;
    padding-left: 2.75em;
    padding-right: 1.5em;
    margin-bottom: .5em;
  }

  .hide-media-captions .has-media {
    .meta-author {
      margin-bottom: 0;
    }

    &.tiktok .meta-author {
      margin-bottom: 0.3em;
    }
  }

  .no-avatar {
    .meta-author {
      padding-left: 0;
    }

    .avatar {
      display: none;
    }
  }

  .avatar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    margin: auto;
    width: 2.25em;
    height: 2.25em;
    border-radius: 100%;
    box-shadow: 0 0 4px rgba($black, .5);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .user-img {
    visibility: hidden;
    width: 100%;
    height: 100%;
  }

  .author,
  .handle {
    @include ellipsis;
    float: left;
    clear: both;
    max-width: 100%;
    line-height: 2.25;
  }

  .twitter,
  .tiktok {

    .author,
    .handle {
      line-height: 1.3;
    }

    .handle {
      font-size: .8em;
    }
  }

  .reddit .subreddit {
    float: left;
    clear: both;
    max-width: 100%;
    line-height: 1.3;
    @include ellipsis;

    + .handle {
      font-size: .8em;
      line-height: 1.3;
    }
  }

  .post-time {
    display: none;
    font-size: .8em;
    margin-bottom: .5em;
    @include ellipsis;
  }

  .timestamp {
    font-size: .8em;
  }

  .post-title {
    margin-bottom: .5em;
    font-weight: bold;
  }

  .audio-attribution {
    position: relative;
    color: #fff;
    padding: 0.5em 0;
    font-size: .6em;
    line-height: 1.75;
  }

  .audio-avatar {
    display: inline-block;
    width: 1.75em;
    height: 1.75em;
    margin-left: .15em;
    margin-bottom: -.3em;
    border-radius: 100%;
    background-color: transparent;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    animation: spin 8s linear infinite;

    @keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }

  .audio-info {
    width: calc(25% - 2em);
    display: inline-block;
    margin-left: .15em;
    margin-bottom: -.3em;
    overflow: hidden;
    mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 8%, rgba(255, 255, 255, 1) 92%, rgba(255, 255, 255, 0) 100%);
    -webkit-mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 8%, rgba(255, 255, 255, 1) 92%, rgba(255, 255, 255, 0) 100%);
  }

  .audio-info-inner {
    display: inline-block;
    white-space: nowrap;
    transform: translateX(100%);
    animation: marquee 8x linear infinite;
    -webkit-animation: marquee 8s linear infinite;

    @keyframes marquee {
      0% {
        transform: translateX(0%);
      }

      100% {
        transform: translateX(-33.333333%);
      }
    }

    @-webkit-keyframes marquee {
      0% {
        -webkit-transform: translateX(0%);
      }

      100% {
        -webkit-transform: translateX(-33.333333%);
      }
    }
  }

  .origin-flag {
    top: 5vmin;
    right: 5%;
  }

  // CUSTOMIZATIONS

  .crop-images {
    .media-module {
      background-size: cover;
    }

    .media {
      object-fit: cover;
    }
  }

  .show-blurred-images {
    .blurred-media-module {
      display: block;
    }
  }

  // kenBurns (only applies to Cinematic)

  @-webkit-keyframes kenBurns {
    from {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes kenBurns {
    from {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  [data-animation="Ken Burns"] {

    .media-module,
    .smart-panel.graphic {
      -webkit-animation-duration: 6s;
      animation-duration: 6s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-timing-function: ease;
      animation-timing-function: ease;
      -webkit-animation-name: kenBurns;
      animation-name: kenBurns;
    }
  }
}
